/**
* Template Name: Dashio
* Template URL: https://templatemag.com/dashio-bootstrap-admin-template/
* Author: TemplateMag.com
* License: https://templatemag.com/license/
*/
/*
Template Name: DASHIO - Bootstrap 3.1.1 Admin Theme
Template Version: 1.0
Author: Carlos Alvarez
Website: http://Alvarez.is/
*/

/* Import fonts */

/* BASIC THEME CONFIGURATION */
body {
    color: #797979;
	background: #eaeaea;
    font-family: 'Ruda', sans-serif;
    padding: 0px !important;
    margin: 0px !important;
    font-size:13px;
}

ul li {
    list-style: none;
}

a, a:hover, a:focus {
    text-decoration: none;
    outline: none;
}
::selection {

    background: #4ECDC4;
    color: #fff;
}
::-moz-selection {
    background: #4ECDC4;
    color: #fff;
}

#container {
    width: 100%;
    height: 100%;
}

/* Bootstrap Modifications */
.modal-header {
	background: #4ECDC4;
}

.modal-title {
	color: white;
}

.btn-round {
	border-radius: 20px;
	-webkit-border-radius: 20px;
}

.accordion-heading .accordion-toggle {
	display: block;
	cursor: pointer;
	border-top: 1px solid #F5F5F5;
	padding: 5px 0px;
	line-height: 28.75px;
	text-transform: uppercase;
	color: #1a1a1a;
	background-color: #ffffff;
	outline: none !important;
	text-decoration: none;
}



/*Theme Backgrounds*/

.bg-theme {
	background-color: #4ECDC4;
}

.bg-theme02 {
	background-color: #ac92ec;
}

.bg-theme03 {
  background-color: #48cfad;
}

.bg-theme04 {
  background-color: #ed5565;
}
/*Theme Buttons*/

.btn-theme {
  color: #fff;
  background-color: #4ECDC4;
  border-color: #48bcb4;
}
.btn-theme:hover,
.btn-theme:focus,
.btn-theme:active,
.btn-theme.active,
.open .dropdown-toggle.btn-theme {
  color: #fff;
  background-color: #48bcb4;
  border-color: #48bcb4;
}

.btn-theme02 {
  color: #fff;
  background-color: #ac92ec;
  border-color: #967adc;
}
.btn-theme02:hover,
.btn-theme02:focus,
.btn-theme02:active,
.btn-theme02.active,
.open .dropdown-toggle.btn-theme02 {
  color: #fff;
  background-color: #967adc;
  border-color: #967adc;
}

.btn-theme03 {
  color: #fff;
  background-color: #48cfad;
  border-color: #37bc9b;
}
.btn-theme03:hover,
.btn-theme03:focus,
.btn-theme03:active,
.btn-theme03.active,
.open .dropdown-toggle.btn-theme03 {
  color: #fff;
  background-color: #37bc9b;
  border-color: #37bc9b;
}

.btn-theme04 {
  color: #fff;
  background-color: #ed5565;
  border-color: #da4453;
}
.btn-theme04:hover,
.btn-theme04:focus,
.btn-theme04:active,
.btn-theme04.active,
.open .dropdown-toggle.btn-theme04 {
  color: #fff;
  background-color: #da4453;
  border-color: #da4453;
}

.btn-clear-g {
	color: #48bcb4;
	background: transparent;
	border-color: #48bcb4;
}

.btn-clear-g:hover {
	color: white;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #797979;
}


#loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: #fff;
}
#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 1px solid transparent;
    border-top-color: #000;
    -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 1px solid transparent;
    border-top-color: #8e908f;
    -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
      animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}
 
#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 1px solid transparent;
    border-top-color: #d0cfcb;
    -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
      animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}
 

/*Helpers*/

.centered {
	text-align: center;
}

.goleft {
	text-align: left;
}

.goright {
	text-align: right;
}

.mt {
	margin-top: 25px;
}

.mb {
	margin-bottom: 25px;
}


.no-padding {
	padding: 0 !important;
}

.no-margin {
	margin: 0 !important;
}

/*Exclusive Theme Colors Configuration*/

.label-theme {
	background-color: #4ECDC4;
}

.bg-theme {
	background-color: #4ECDC4;
}

ul.top-menu > li > .logout {
	color: #f2f2f2;
	font-size: 12px;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	border: 1px solid #64c3c2 !important;
	padding: 5px 15px;
	margin-right: 15px;
	background: #4ECDC4;
	margin-top: 15px;
}


/*sidebar navigation*/

#sidebar {
    width: 210px;
    height: 100%;
    position: fixed;
    background: #2f323a;
}

#sidebar h5 {
	color: #f2f2f2;
	font-weight: 700;
}

#sidebar ul li {
    position: relative;
}

#sidebar .sub-menu > .sub li  {
    padding-left: 32px;
}

#sidebar .sub-menu > .sub li:last-child {
    padding-bottom: 10px;
}

.nav-collapse.collapse {
    display: inline;
}

ul.sidebar-menu , ul.sidebar-menu li ul.sub{
    margin: -2px 0 0;
    padding: 0;
}

ul.sidebar-menu {
    margin-top: 75px;
}

#sidebar > ul > li > ul.sub {
    display: none;
}

#sidebar > ul > li.active > ul.sub, #sidebar > ul > li > ul.sub > li > a {
    display: block;
}

ul.sidebar-menu li ul.sub li{
    background: #2f323a;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
}

ul.sidebar-menu li ul.sub li:last-child{
    border-radius: 0 0 4px 4px;
    -webkit-border-radius: 0 0 4px 4px;
}

ul.sidebar-menu li ul.sub li a {
    font-size: 12px;
    padding: 6px 0;
    line-height: 35px;
    height: 35px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    color: #aeb2b7;
}

ul.sidebar-menu li ul.sub li a:hover {
	color: white;
	background: transparent;
}

ul.sidebar-menu li ul.sub li.active a {
    color: #4ECDC4;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    display: block;
}

ul.sidebar-menu li{
    /*line-height: 20px !important;*/
    margin-bottom: 5px;
    margin-left:10px;
    margin-right:10px;
}

ul.sidebar-menu li.sub-menu{
    line-height: 15px;
}

ul.sidebar-menu li a span{
    display: inline-block;
}

ul.sidebar-menu li a{
    color: #aeb2b7;
    text-decoration: none;
    display: block;
    padding: 15px 0 15px 10px;
    font-size: 12px;
    outline: none;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

ul.sidebar-menu li a.active, ul.sidebar-menu li a:hover, ul.sidebar-menu li a:focus {
    background: #4ECDC4;
    color: #fff;
    display: block;

    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}


ul.sidebar-menu li a i {
    font-size: 15px;
    padding-right: 6px;
}

ul.sidebar-menu li a:hover i, ul.sidebar-menu li a:focus i {
    color: #fff;
}

ul.sidebar-menu li a.active i {
    color: #fff;
}


.mail-info, .mail-info:hover {
    margin: -3px 6px 0 0;
    font-size: 11px;
}

/* MAIN CONTENT CONFIGURATION */
#main-content {
    margin-left: 210px;
}

.header, .footer {
    min-height: 60px;
    padding: 0 15px;
}

.header {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1002;
}

.black-bg {
    background: #22242a;
    border-bottom: 1px solid #393d46;
}

.wrapper {
    display: inline-block;
    margin-top: 60px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-top: 0px;
    width: 100%;
}

a.logo {
    font-size: 24px;
    color: #f2f2f2;
    float: left;
    margin-top: 15px;
    text-transform: uppercase;
}

a.logo b {
    font-weight: 900;
}

a.logo:hover, a.logo:focus {
    text-decoration: none;
    outline: none;
}

a.logo span {
    color: #4ECDC4;
}


/*notification*/
#top_menu .nav > li, ul.top-menu > li {
    float: left;
}

.notify-row {
    float: left;
    margin-top: 15px;
    margin-left: 92px;
}

.notify-row .notification span.label {
    display: inline-block;
    height: 18px;
    width: 20px;
    padding: 5px;
}

ul.top-menu > li > a {
    color: #666666;
    font-size: 16px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    border:1px solid #666666 !important;
    padding: 2px 6px;
    margin-right: 15px;
}

ul.top-menu > li > a:hover, ul.top-menu > li > a:focus {
    border:1px solid #b6b6b6 !important;
    background-color: transparent !important;
    border-color: #b6b6b6 !important;
    text-decoration: none;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    color: #b6b6b6 !important;
}

.notify-row .badge {
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 100;
}

.dropdown-menu.extended {
    max-width: 300px !important;
    min-width: 160px !important;
    top: 42px;
    width: 235px !important;
    padding: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.176) !important;
    border: none !important;
    border-radius: 4px;
    -webkit-border-radius: 4px;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    /* Safari and Chrome */
    .dropdown-menu.extended  {
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.176) !important;
    };
    }

.dropdown-menu.extended li p {
    background-color: #F1F2F7;
    color: #666666;
    margin: 0;
    padding: 10px;
    border-radius: 4px 4px 0px 0px;
    -webkit-border-radius: 4px 4px 0px 0px;
}

.dropdown-menu.extended li p.green {
    background-color: #4ECDC4;
    color: #fff;
}

.dropdown-menu.extended li p.yellow {
    background-color: #fcb322;
    color: #fff;
}

.dropdown-menu.extended li a {
    border-bottom: 1px solid #EBEBEB !important;
    font-size: 12px;
    list-style: none;
}

.dropdown-menu.extended li a {
    padding: 15px 10px !important;
    width: 100%;
    display: inline-block;
}

.dropdown-menu.extended li a:hover {
    background-color: #F7F8F9 !important;
    color: #2E2E2E;
}

.dropdown-menu.tasks-bar .task-info .desc {
    font-size: 13px;
    font-weight: normal;
}

.dropdown-menu.tasks-bar .task-info .percent {
    display: inline-block;
    float: right;
    font-size: 13px;
    font-weight: 600;
    padding-left: 10px;
    margin-top: -4px;
}

.dropdown-menu.extended .progress {
    margin-bottom: 0 !important;
    height: 10px;
}

.dropdown-menu.inbox li a .photo img {
    border-radius: 2px 2px 2px 2px;
    float: left;
    height: 40px;
    margin-right: 4px;
    width: 40px;
}

.dropdown-menu.inbox li a .subject {
    display: block;
}

.dropdown-menu.inbox li a .subject .from {
    font-size: 12px;
    font-weight: 600;
}

.dropdown-menu.inbox li a .subject .time {
    font-size: 11px;
    font-style: italic;
    font-weight: bold;
    position: absolute;
    right: 5px;
}

.dropdown-menu.inbox li a .message {
    display: block !important;
    font-size: 11px;
}

.top-nav  {
    margin-top: 7px;
}

.top-nav ul.top-menu > li .dropdown-menu.logout {
    width: 268px !important;

}

.top-nav li.dropdown .dropdown-menu {
    float: right;
    right: 0;
    left: auto;
}

.dropdown-menu.extended.logout > li {
    float: left;
    text-align: center;
    width: 33.3%;
}

.dropdown-menu.extended.logout > li:last-child {
    float: left;
    text-align: center;
    width: 100%;
    background: #a9d96c;
    border-radius: 0 0 3px 3px;
}

.dropdown-menu.extended.logout > li:last-child > a, .dropdown-menu.extended.logout > li:last-child > a:hover {
    color: #fff;
    border-bottom: none !important;
    text-transform: uppercase;
}

.dropdown-menu.extended.logout > li:last-child > a:hover > i{
    color: #fff;
}

.dropdown-menu.extended.logout > li > a {
    color: #a4abbb;
    border-bottom: none !important;
}

.full-width .dropdown-menu.extended.logout > li > a:hover {
    background: none !important;
    color: #50c8ea !important;
}

.dropdown-menu.extended.logout > li > a:hover {
    background: none !important;
}

.dropdown-menu.extended.logout > li > a:hover i {
    color: #50c8ea;
}

.dropdown-menu.extended.logout > li > a i {
    font-size: 17px;
}

.dropdown-menu.extended.logout > li > a > i {
    display: block;
}

.top-nav ul.top-menu > li > a {
    border: 1px solid #eeeeee;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    padding: 6px;
    background: none;
    margin-right: 0;
}
.top-nav ul.top-menu > li {
    margin-left: 10px;
}

.top-nav ul.top-menu > li > a:hover, .top-nav ul.top-menu > li > a:focus {
    border:1px solid #F1F2F7;
    background: #F1F2F7;

}

.top-nav .dropdown-menu.extended.logout {
    top: 50px;
}

.top-nav .nav .caret {
    border-bottom-color: #A4AABA;
    border-top-color: #A4AABA;
}
.top-nav ul.top-menu > li > a:hover .caret {
    border-bottom-color: #000;
    border-top-color: #000;
}

/*----*/

.notify-arrow {
    border-style: solid;
    border-width: 0 9px 9px;
    height: 0;
    margin-top: 0;
    opacity: 0;
    position: absolute;
    left: 7px;
    top: -18px;
    transition: all 0.25s ease 0s;
    width: 0;
    z-index: 10;
    margin-top: 10px;
    opacity: 1;
}

.notify-arrow-yellow {
    border-color: transparent transparent #FCB322;
    border-bottom-color: #FCB322 !important;
    border-top-color: #FCB322 !important;
}
.notify-arrow-green {
    border-color: transparent transparent #4ECDC4;
    border-bottom-color: #4ECDC4 !important;
    border-top-color: #4ECDC4 !important;
}



/*--sidebar toggle---*/

.sidebar-toggle-box {
    float: left;
    padding-right: 15px;
    margin-top: 20px;
}

.sidebar-toggle-box .fa-bars {
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
}


.sidebar-closed > #sidebar > ul {
    display: none;
}

.sidebar-closed #main-content {
    margin-left: 0px;
}

.sidebar-closed #sidebar {
    margin-left: -180px;
}


/* Dash Side */

.ds {
	background: #d6d9dc;
	padding-top: 20px;
}

.ds h4 {
	font-size: 14px;
	font-weight: 700;
}

.ds i {
	font-size: 14px;
}

.ds .desc {
	border-bottom: 1px dotted #979797;
	display: inline-block;
	padding: 15px 0;
	width: 100%;
}

.ds .desc:hover {
	background: #ffffff;
}

.ds .thumb {
	width: 30px;
	margin: 0 10px 0 20px;
	display: block;
	float: left;
}

.ds .details {
	width: 170px;
	float: left;
}

.ds > .desc p {
	font-size: 11px;
}

.ds p > muted {
	font-size: 9px;
	text-transform: uppercase;
	font-style: italic;
	color: #666666
}

.ds a {
	color: #4ECDC4;
}

/* MAIN CHART CONFIGURATION */
.main-chart {
	padding-top: 20px;
}

.border-head h3 {
    border-bottom: 1px solid #c9cdd7;
    margin-top: 0;
    margin-bottom: 20px;
    padding-bottom: 5px;
    font-weight: normal;
    font-size: 18px;
    display: inline-block;
    width: 100%;
    font-weight: 300;
}

.custom-bar-chart {
    height: 290px;
    margin-top: 20px;
    margin-left: 10px;
    position: relative;
    border-bottom: 1px solid #c9cdd7;
}

.custom-bar-chart .bar {
    height: 100%;
    position: relative;
    width: 6%;
    margin: 0px 4%;
    float: left;
    text-align: center;
    z-index: 10;
}

.custom-bar-chart .bar .title {
    position: absolute;
    bottom: -30px;
    width: 100%;
    text-align: center;
    font-size: 11px;
}

.custom-bar-chart .bar .value {
    position: absolute;
    bottom: 0;
    background: #4ECDC4;
    color: #4ECDC4;
    width: 100%;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}



.custom-bar-chart .bar .value:hover {
    background: #2f2f2f;
    color: #fff;
}

.y-axis {
    color: #555555;
    position: absolute;
    text-align: left;
    width: 100%;
    font-size: 11px;
}

.y-axis li {
    border-top: 1px dashed #dbdce0;
    display: block;
    height: 58px;
    width: 100%;
}

.y-axis li:last-child {
    border-top: none;
}

.y-axis li span {
    display: block;
    margin: -10px 0 0 -60px;
    padding: 0 10px;
    width: 40px;
}


/*Donut Chart Main Page Conf*/
.donut-main {
	display: block;
	text-align: center;
}

.donut-main h4 {
	font-weight: 700;
	font-size: 16px;
}

/* *************************************************************************************
PANELS CONFIGURATIONS
*************************************************************************************** */

/*Panel Size*/

.pn {
	height: 250px;
	box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}


/*Grey Panel*/

.grey-panel {
	text-align: center;
	background: #dfdfe1;
}
.grey-panel .grey-header {
	background: #ccd1d9;
	padding: 3px;
	margin-bottom: 15px;
}
.grey-panel h5 {
	font-weight: 200;
	margin-top: 10px;
}
.grey-panel p {
	margin-left: 5px;
}
/* Specific Conf for Donut Charts*/
.donut-chart p {
	margin-top: 20px;
	font-weight: 700;
	margin-left: 15px;
}
.donut-chart h2 {
	font-weight: 900;
	color: #FF6B6B;
	font-size: 38px;
}

/* Dark Blue*/

.darkblue-panel {
	text-align: center;
	background: #444c57;
}
.darkblue-panel .darkblue-header {
	background: transparent;
	padding: 3px;
	margin-bottom: 15px;
}
.darkblue-panel h1 {
	color: #f2f2f2;
}
.darkblue-panel h5 {
	font-weight: 200;
	margin-top: 10px;
	color: white;
}
.darkblue-panel footer {
	color: white;
}
.darkblue-panel footer h5 {
	margin-left:10px;
	margin-right: 10px;
	font-weight: 700;
}

/*Green Panel*/
.green-panel {
	text-align: center;
	background: #4ECDC4;
}
.green-panel .green-header {
	background: #43b1a9;
	padding: 3px;
	margin-bottom: 15px;
}
.green-panel h5 {
	color: white;
	font-weight: 200;
	margin-top: 10px;
}
.green-panel h3 {
	color: white;
	font-weight: 100;
}
.green-panel p {
	color: white;
}

/*White Panel */
.white-panel {
	text-align: center;
	background: #ffffff;
}
.white-panel .white-header {
	background: #f3f3f3;
	padding: 3px;
	margin-bottom: 15px;
}
.white-panel .small {
	font-size: 10px;
	color: #ccd1d9;
}

/*STOCK CARD Panel*/
.card {
  background: white;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
  margin-bottom: 1em;
  height: 250px;
}
.stock .stock-chart {
  background: #00c5de;
}
.stock .stock-chart #chart {
    height: 10.7em;
    background: url(http://i.imgbox.com/abmuNQx2) center bottom no-repeat;
}
.stock .current-price {
  background: #1d2122;
  padding: 10px;
}
.stock .current-price .info abbr {
    display: block;
    color: #f8f8f8;
    font-size: 1.5em;
    font-weight: 600;
    margin-top: 0.18em;
}
.stock .current-price .changes {
    text-align: right;
}
.stock .changes .up {
	color: #4fd98b
}
.stock .current-price .changes .value {
      font-size: 1.8em;
      font-weight: 700;
}
.stock .summary {
    color: #2f2f2f;
    display: block;
    background: #f2f2f2;
    padding: 10px;
	text-align: center;
}
.stock .summary strong {
	font-weight: 900;
	font-size: 14px;
}

/*Content Panel*/
.content-panel {
	background: #ffffff;
	box-shadow: 0px 3px 2px #aab2bd;
	padding-top: 15px;
	padding-bottom: 5px;
}
.content-panel h4 {
	margin-left: 10px;
}



/* Weather 2 */
.weather-2 {
	background: #e9f0f4;
}
.weather-2 .weather-2-header {
	background: #54bae6;
	padding-top: 5px;
	margin-bottom: 5px;
}
.weather-2 .weather-2-header p {
	color: white;
	margin-left: 5px;
	margin-right: 5px;
}
.weather-2 .weather-2-header p small {
	font-size: 10px;
}
.weather-2 .data {
	margin-right: 10px;
	margin-left: 10px;
	color: #272b34;
}
.weather-2 .data h5 {
	margin-top: 0px;
	font-weight: lighter;
}
.weather-2 .data h1{
	margin-top: 2px;
}

/* Weather 3 */
.weather-3 {
	background: #ffcf00;
}

.weather-3 i {
	color: white;
	margin-top: 30px;
	font-size: 70px;
}
.weather-3 h1 {
	margin-top: 10px;
	color: white;
	font-weight: 900;
}
.weather-3 .info {
	background: #f2f2f2;
}
.weather-3 .info i {
	font-size: 15px;
	color: #c2c2c2;
	margin-bottom: 0px;
	margin-top: 10px;
}
.weather-3 .info h3 {
	font-weight: 900;
	margin-bottom: 0px;
}
.weather-3 .info p {
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 16px;
	color: #c2c2c2;
	font-size: 15px;
	font-weight: 700;
}


/* Message Panel */
.message-p {
	background: #f5f5f5;
}
.message-p .message-header {
	background: #dcdfe3;
	padding: 3px;
	margin-bottom: 15px;
	text-align: center;
}
.message-p h5 {
	font-weight: 200;
	margin-top: 10px;
}
.message-p p {
	margin-left: 10px;
}
.message-p .small {
	font-size: 11px;
	color: #aab2bd;
	margin-top: -10px;
}
.message-p name {
	color: #ed5565;
	font-weight: 700;
}
.message-p .message {
	font-size: 12px;
}
.message-p .form-inline {
	margin-left: 10px;
}
.message-p .form-inline .form-group {
	width: 80%;
}
.message-p .form-inline .form-control {
	width: 100%
}

/*Twitter Panel*/
.twitter-panel {
	background: #4fc1e9;
	text-align: center;
}
.twitter-panel i {
	color: white;
	margin-top: 40px;
}
.twitter-panel p {
	color: #f5f5f5;
	margin: 10px;
}
.twitter-panel .user {
	color: white;
	font-weight: 900;
}

/* Product Panel */
.product-panel {
	background: #dadad2;
	text-align: center;
	padding-top: 50px;
	height: 100%;
}

/* Product Panel 2*/
.product-panel-2 {
	background: #dadad2;
	text-align: center;
}
.product-panel-2 .badge {
	position: absolute;
	top: 20px;
	left: 35px;
}
.badge-hot {
	background: #ed5565;
	width: 70px;
	height: 70px;
	line-height: 70px;
	font-size: 18px;
	color: #fff;
	text-align: center;
	border-radius: 100%;
}

/* Calendar Configuration */
#calendar {
	color: white;
	padding: 0px !important;
}
.calendar-month-header {
	background: #43b1a9;
}

/* TODO PANEL */
.steps{
  display: block;
}
.steps input[type=checkbox] {
  display: none;
}
.steps input[type=submit]{
  background: #f1783c;
  border: none;
  padding: 0px;
  margin: 0 auto;
  width: 100%;
  height: 55px;
  color: white;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 11px;
  letter-spacing: 1px;
  cursor: pointer;
  transition: background 0.5s ease
}
.steps input[type=submit]:hover{
  background: #8fde9c;
}
.steps label{
  background: #393D40;
  height: 65px;
  line-height: 65px;
  width: 100%;
  display: block;
  border-bottom: 1px solid #44494e;
  color: #889199;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 11px;
  letter-spacing: 1px;
  text-indent: 52px;
  cursor: pointer;
  transition: all 0.7s ease;
  margin: 0px;
}
.steps label:before{
  content:"";
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  margin: 26px 0px 0px 18px;
  border-radius: 100%;
  transition: border 0.7s ease
}
.steps label:hover{
  background: #2B2E30;
  color: #46b7e5
}
.steps label:hover:before{
  border: 1px solid #46b7e5;
}
#op1:checked ~ label[for=op1]:before,
#op2:checked ~ label[for=op2]:before,
#op3:checked ~ label[for=op3]:before{
  border: 2px solid #96c93c;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAHCAYAAAA1WQxeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAGFJREFUeNpinHLMhgEHKADia0xQThIQs6JJ9gPxZhYQAcS6QHwDiI8hSYJAC0gBPxDLAvFcIJ6JJJkDxFNBVtgBcQ8Qa6BLghgwN4A4a9ElQYAFSj8C4mwg3o8sCQIAAQYA78QTYqnPZuEAAAAASUVORK5CYII=') no-repeat center center;
}

/*spark line*/
.chart {
    display: inline-block;
    text-align: center;
    width: 100%;
}
.chart .heading {
    text-align: left;
}
.chart .heading span {
    display: block;
}
.panel.green-chart .chart-tittle {
    font-size: 16px;
    padding: 15px;
    display: inline-block;
    font-weight:normal;
    background: #99c262;
    width: 100%;
    -webkit-border-radius: 0px 0px 4px 4px;
    border-radius: 0px 0px 4px 4px;
}
#barchart {
    margin-bottom: -15px;
    display: inline-block;
}
.panel.green-chart .chart-tittle .value {
    float: right;
    color: #c0f080;
}
.panel.green-chart {
    background: #a9d96c;
    color: #fff;
}
.panel.terques-chart {
    background: transparent;
    color: #797979;
}
.panel.terques-chart .chart-tittle .value {
    float: right;
    color: #fff;
}
.panel.terques-chart .chart-tittle .value a {
    color: #2f2f2f;
    font-size: 12px;
}
.panel.terques-chart .chart-tittle .value a:hover, .panel.terques-chart .chart-tittle .value a.active {
    color: #4ECDC4;
    font-size: 12px;
}
.panel.terques-chart .chart-tittle {
    font-size: 16px;
    padding: 15px;
    display: inline-block;
    font-weight:normal;
    background: #39b7ac;
    width: 100%;
    -webkit-border-radius: 0px 0px 4px 4px;
    border-radius: 0px 0px 4px 4px;
}
.inline-block {
    display: inline-block;
}

/* showcase background */
.showback {
	background: #ffffff;
	padding: 15px;
	margin-bottom: 15px;
	box-shadow: 0px 3px 2px #aab2bd;
}

/* Font Awesome Showcase */
.fontawesome-list .fa-hover a {
	display: block;
	color: #222;
	line-height: 32px;
	height: 32px;
	padding-left: 10px;
	border-radius: 4px;
}

.fontawesome-list i {
	margin-right: 8px;
}

.show-grid [class^=col-] {
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: #ccc;
	border: 1px solid #ddd;
}

.show-grid [class^=col-]:hover {
	background-color: white;
}

/* Calendar Events - Calendar.html*/
.external-event {
	cursor: move;
	display: inline-block !important;
	margin-bottom: 7px !important;
	margin-right: 7px !important;
	padding: 10px;
}

.drop-after {
	padding-top: 15px;
	margin-top: 15px;
	border-top: 1px solid #ccc;
}

.fc-state-default, .fc-state-default .fc-button-inner {
	background: #f2f2f2;
}

.fc-state-active .fc-button-inner {
	background: #FFFFFF;
}

/* Gallery Configuration */
.photo-wrapper {
  display: block;
  position: relative;
  overflow: hidden;
  background-color: #4ECDC4;
  -webkit-transition: background-color 0.4s;
  -moz-transition: background-color 0.4s;
  -o-transition: background-color 0.4s;
  transition: background-color 0.4s;
}
.project .overlay {
  position: absolute;
  text-align: center;
  color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  transition: opacity 0.4s;

}

.project:hover .photo-wrapper {
  background-color: #4ECDC4;
	background-image:url(/img/zoom.png);
	background-repeat:no-repeat;
	background-position:center;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	position: relative;
}
.project:hover .photo {
  opacity: 10;
  filter: alpha(opacity=4000);
  opacity: 0.1;
  filter: alpha(opacity=40);
}
.project:hover .overlay {
  opacity: 100;
  filter: alpha(opacity=10000);
  opacity: 1;
  filter: alpha(opacity=100);
}



/* EZ Checklist */
.ez-checkbox {
	margin-right: 5px;
}

.ez-checkbox, .ez-radio {
	height: 20px;
	width: 20px;
}

.brand-highlight {
background: #fffbcc !important;
}


/* FORMS CONFIGURATION */
.form-panel {
	background: #ffffff;
	margin: 10px;
	padding: 10px;
	box-shadow: 0px 3px 2px #aab2bd;
	text-align: left;
}

label {
	font-weight: 400;
}

.form-horizontal.style-form .form-group {
	border-bottom: 1px solid #eff2f7;
	padding-bottom: 15px;
	margin-bottom: 15px;
}

.round-form {
	border-radius: 500px;
	-webkit-border-radius: 500px;
}

@media (min-width: 768px) {
		.form-horizontal .control-label {
		text-align: left;
	}
}

#focusedInput {
	border: 1px solid #ed5565;
	box-shadow: none;
}

.add-on {
	float: right;
	margin-top: -37px;
	padding: 3px;
	text-align: center;
}

.add-on .btn {
	height: 34px;
}

/* TOGGLE CONFIGURATION */
.has-switch {
    border-radius: 30px;
    -webkit-border-radius: 30px;
    display: inline-block;
    cursor: pointer;
    line-height: 1.231;
    overflow: hidden;
    position: relative;
    text-align: left;
    width: 80px;
    -webkit-mask: url('/img/mask.png') 0 0 no-repeat;
    mask: url('/img/mask.png') 0 0 no-repeat;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.has-switch.deactivate {
    opacity: 0.5;
    filter: alpha(opacity=50);
    cursor: default !important;
}
.has-switch.deactivate label,
.has-switch.deactivate span {
    cursor: default !important;
}
.has-switch > div {
    width: 162%;
    position: relative;
    top: 0;
}
.has-switch > div.switch-animate {
    -webkit-transition: left 0.25s ease-out;
    -moz-transition: left 0.25s ease-out;
    -o-transition: left 0.25s ease-out;
    transition: left 0.25s ease-out;
    -webkit-backface-visibility: hidden;
}
.has-switch > div.switch-off {
    left: -63%;
}
.has-switch > div.switch-off label {
    background-color: #7f8c9a;
    border-color: #bdc3c7;
    -webkit-box-shadow: -1px 0 0 rgba(255, 255, 255, 0.5);
    -moz-box-shadow: -1px 0 0 rgba(255, 255, 255, 0.5);
    box-shadow: -1px 0 0 rgba(255, 255, 255, 0.5);
}
.has-switch > div.switch-on {
    left: 0%;
}
.has-switch > div.switch-on label {
    background-color: #41cac0;
}
.has-switch input[type=checkbox] {
    display: none;
}
.has-switch span {
    cursor: pointer;
    font-size: 14.994px;
    font-weight: 700;
    float: left;
    height: 29px;
    line-height: 19px;
    margin: 0;
    padding-bottom: 6px;
    padding-top: 5px;
    position: relative;
    text-align: center;
    width: 50%;
    z-index: 1;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: 0.25s ease-out;
    -moz-transition: 0.25s ease-out;
    -o-transition: 0.25s ease-out;
    transition: 0.25s ease-out;
    -webkit-backface-visibility: hidden;
}
.has-switch span.switch-left {
    border-radius: 30px 0 0 30px;
    background-color: #2A3542;
    color: #41cac0;
    border-left: 1px solid transparent;
}
.has-switch span.switch-right {
    border-radius: 0 30px 30px 0;
    background-color: #bdc3c7;
    color: #ffffff;
    text-indent: 7px;
}
.has-switch span.switch-right [class*="fui-"] {
    text-indent: 0;
}
.has-switch label {
    border: 4px solid #2A3542;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    float: left;
    height: 29px;
    margin: 0 -21px 0 -14px;
    padding: 0;
    position: relative;
    vertical-align: middle;
    width: 29px;
    z-index: 100;
    -webkit-transition: 0.25s ease-out;
    -moz-transition: 0.25s ease-out;
    -o-transition: 0.25s ease-out;
    transition: 0.25s ease-out;
    -webkit-backface-visibility: hidden;
}
.switch-square {
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -webkit-mask: url('/img/mask.png') 0 0 no-repeat;
    mask: url('/img/mask.png') 0 0 no-repeat;
}
.switch-square > div.switch-off label {
    border-color: #7f8c9a;
    border-radius: 6px 0 0 6px;
}
.switch-square span.switch-left {
    border-radius: 6px 0 0 6px;
}
.switch-square span.switch-left [class*="fui-"] {
    text-indent: -10px;
}
.switch-square span.switch-right {
    border-radius: 0 6px 6px 0;
}
.switch-square span.switch-right [class*="fui-"] {
    text-indent: 5px;
}
.switch-square label {
    border-radius: 0 6px 6px 0;
    border-color: #41cac0;
}


/* TABLES CONF*/
.dataTables_paginate.paging_bootstrap.pagination {
	float: right;
	margin-top: -5px;
	margin-bottom: 15px;
}

.dataTables_paginate {
	padding: 15px 0;
}
.dataTables_paginate.paging_bootstrap.pagination li {
	float: left;
	margin: 0 1px;
	border: 1px solid #ddd;
	border-radius: 3px;
	-webkit-border-radius: 3px;
}
ul li {
	list-style: none;
}
.dataTables_paginate.paging_bootstrap.pagination li a {
	color: #797979;
	padding: 5px 10px;
	display: inline-block;
}
.dataTables_paginate.paging_bootstrap.pagination li:hover a, .dataTables_paginate.paging_bootstrap.pagination li.active a {
	color: #fff;
	background: #4ECDC4;
	border-radius: 3px;
	-webkit-border-radius: 3px;
}

/*LOGIN CONFIGURATION PAGE*/
.form-login {
	max-width: 330px;
	margin: 100px auto 0;
	background: #fff;
	border-radius: 5px;
	-webkit-border-radius: 5px;
}

.form-login h2.form-login-heading {
	margin: 0;
	padding: 25px 20px;
	text-align: center;
	background: #4ecdc4;
	border-radius: 5px 5px 0 0;
	-webkit-border-radius: 5px 5px 0 0;
	color: #fff;
	font-size: 20px;
	text-transform: uppercase;
	font-weight: 300;
}
.login-wrap {
	padding: 20px;
}
.login-wrap .registration {
	text-align: center;
}
.login-social-link {
	display: block;
	margin-top: 20px;
	margin-bottom: 15px;
}


/*LOCK SCREEN CONF*/
#showtime {
	width: 100%;
	color: #fff;
	font-size: 50px;
	margin-bottom: 30px;
	margin-top: 250px;
	display: inline-block;
	text-align: center;
	font-weight: 400;
}

.lock-screen {
	text-align: center;
}

.lock-screen a {
	color: white;
}

.lock-screen a:hover {
	color: #48cfad
}

.lock-screen i {
	font-size: 60px;
}

.lock-screen .modal-content {
	position: relative;
	background-color: #f2f2f2;
	background-clip: padding-box;
	border: 1px solid #999;
	border: 1px solid rgba(0, 0, 0, .2);
	border-radius: 5px;
}


/* PROFILE PAGE CONF */
.profile-pic img {
	width: 130px;
	height: 130px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	border: 10px solid #f1f2f7;
	margin-top: 20px;
}

.profile-text h6 {
	margin-top: -10px;
	margin-bottom: 20px;
	color: #d3d3d3;
}

.profile-text h3 {
	font-weight: 700;
	color: #48cfad;
}

.profile-text h4 {
	color: #48cfad;
}

profile-text p {
	margin-left: 10px;
}

.right-divider {
	border-right: 1px solid #d3d3d3;
	height: 100%;
}

.panel-heading .nav {
	border: medium none;
	font-size: 13px;
	margin-left: 0px;
	margin-right: 0px;
	text-transform: uppercase;
}

.nav-tabs {
	background: #e0e1e7;
}

.nav-tabs a {
	color: #2f2f2f;
}

.grey-style {
	background: #f2f4f6;
	padding: 10px;
	height: 45px;
}

.detailed h4 {
	text-align: center;
	text-transform: uppercase;
	border-bottom: 1px solid #e2e2e2;
	font-weight: 700;
	color: #48cfad;
}

ul.my-friends {
	margin: 0px;
	margin-top: 16px;
	margin-left: 10px;
	padding: 0;
}

ul.my-friends li .friends-pic {
	display: inline-block;
	float: left;
	overflow: hidden;
	margin: 4px;
}

.recent-activity {
	border-collapse: collapse;
	border-spacing: 0;
	display: table;
	position: relative;
	table-layout: fixed;
	width: 100%;
}

.recent-activity:before {
	background-color: #eeeeee;
	bottom: 0;
	content: "";
	left: 50%;
	position: absolute;
	top: 50px;
	width: 2px;
	z-index: 0;
}

.activity-icon {
	border-radius: 50%;
	-webkit-border-radius: 50%;
	color: #FFFFFF;
	height: 30px;
	line-height: 30px;
	text-align: center;
	width: 30px;
	margin: 20px auto 20px;
	position: relative;
}

.activity-panel {
	padding: 15px 30px;
	background: #f2f2f2;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	position: relative;
	text-align: center;
}

.activity-panel h5{
	font-weight: 700;
}

/* INVOICE CONF */
.well.green {
	background-color: #48cfad;
	color: #fff;
	border: none;
}
.well.well-small {
	padding: 13px;
	width: auto;
}
.invoice-body {
	padding: 30px;
}
.invoice-body h1 {
	font-weight: 900;
}
.invoice-body h4 {
	margin-left: 0px;
}

/* Map Wrap*/
#map {
	width: 100%;
	height: 450px;
}


/*PRICING TABLE*/
.custom-box {
	background:#FFFFFF;
	border: 1px solid #DCDBD7;
	margin: 30px 0;
	padding: 30px 20px 35px;
	text-align: center;
}
.servicetitle hr {
	border: 0 none;
	height:3px;
	margin: 10px auto;
	width: 80px;
	background-color:#4ECDC4;
}
.icn-main-container {
	position: relative;
}
.icn-container {
	display: inline-block;
	width: 100px;
	height: 100px;
	border-radius:500px;
	text-align: center;
	margin:10px 0;
	font-weight:600;
	font-size: 2.4em;
	line-height: 100px;
	color: #fff;
	background-color:#4ECDC4;
		-webkit-transition: all 0.6s ease-in-out;
		transition: all 0.6s ease-in-out;
}
.icn-container.active,
.icn-container:hover {
	background:#2B2E31 !important;
}
.pricing {list-style:none; padding:10px 0 20px;}
.pricing li {
	border-bottom: 1px solid #DCDBD7;
	font-size: 13px;
	font-weight: 100;
	line-height: 34px;
	margin: 0 auto;
	padding: 0;
	text-align: center;
	width: 70%;
	-webkit-transition: background-color .4s linear, color .4s linear;
	-moz-transition: background-color .4s linear, color .4s linear;
	-o-transition: background-color .4s linear, color .4s linear;
	-ms-transition: background-color .4s linear, color .4s linear;
	transition: background-color .4s linear, color .4s linear; }

.pricing li:last-child {border-bottom:0px solid #fff;}


/* FAQ CONF */
.dmbox {
	margin: 0;
	border: 0;
	border: 1px solid #EDEDED;
	background-color: #fff;
	padding: 9px 18px;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	text-align:center;
	-webkit-transition: background 0.3s ease-out;
	transition: background 0.3s ease-out;
}

.dm-icon {
	display: inline-block;
	cursor: pointer;
	margin: 15px;
	width: 105px;
	height: 105px;
	border-radius: 50%;
	text-align: center;
	position: relative;
	z-index: 1;
	background:#2B2E31;
	color: #ffffff;
}
.dm-icon:after {
	pointer-events: none;
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	content: '';
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}

.dm-icon:before {
	speak: none;
	line-height: 105px;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	display: block;
	-webkit-font-smoothing: antialiased;
}

.dm-icon:active,
.dm-icon:hover {
	background: #4ECDC4
}


/* 404 & 500 Error Pages Conf*/
.p404 img {
	margin-top: 120px;
}

.p404 h1 {
	font-weight: 900;
}


/* MAIL CONFIGURATION - INBOX, MAIL COMPOSE & MAIL VIEW  */
.mail-nav {
    margin:15px -15px 0 -15px;
}
.mail-nav li a {
    border-radius:0;
    -webkit-border-radius:0;
    border-top:1px solid #f2f3f6;
    padding:15px;
    border-right:3px solid rgba(0,0,0,0);
    color: #a2a2a2;
}
.mail-nav>li>a:hover,.mail-nav>li>a:focus {
    background:#fafafa;
    color:#4ECDC4;
    border-right:3px solid #1fb5ad;
}
.mail-nav>li.active>a,.mail-nav>li.active>a:hover,.mail-nav>li.active>a:focus {
    background:#fafafa;
    color:#4ECDC4;
    border-right:3px solid #4ECDC4;
}
.mail-nav>li+li {
    margin-left:0;
    margin-top:0px;
}
.mail-nav i {
    font-size:16px;
    margin-right:10px;
}
.inbox-notification {
    margin-top:-2px;
}
.user-head .inbox-avatar {
    width:65px;
    float:left;
}
.user-head .inbox-avatar img {
    border-radius:4px;
    -webkit-border-radius:4px;
}
.user-head .user-name {
    display:inline-block;
    margin:0 0 0 10px;
}
.user-head .user-name h5 {
    font-size:14px;
    margin-top:15px;
    margin-bottom:0;
    font-weight:300;
}
.user-head .user-name h5 a {
    color:#fff;
}
.user-head .user-name span a {
    font-size:12px;
    color:#87e2e7;
}
a.mail-dropdown {
    background:#80d3d9;
    padding:3px 5px;
    font-size:10px;
    color:#01a7b3;
    border-radius:2px;
    margin-top:20px;
}
.inbox-body {
    padding:20px;
}
.btn-compose {
    background:#4ECDC4;
    padding:12px 0;
    text-align:center;
    width:100%;
    color:#fff;
}
.btn-compose:hover,.btn-compose:focus {
    background:#1ca59e;
    color:#fff;
}

.btn-compose i {
	padding: 5px;
}

ul.inbox-nav {
    display:inline-block;
    width:100%;
    margin:0;
    padding:0;
}
.inbox-divider {
    border-bottom:1px solid #d5d8df;
}
.inbox-action {
    margin-top:50px;
}
.src-position {
    margin-top:-7px;
}
.mail-src-position {
    margin-top:-3px;
}
ul.labels-info {
    border-bottom:1px solid #f1f2f7;
    margin-bottom:10px;
}
ul.inbox-nav li {
    display:inline-block;
    line-height:45px;
    width:100%;
}
ul.inbox-nav li a {
    color:#6a6a6a;
    line-height:45px;
    width:100%;
    display:inline-block;
    padding:0 20px;
}
ul.inbox-nav li a:hover,ul.inbox-nav li.active a,ul.inbox-nav li a:focus {
    color:#6a6a6a;
    background:#d5d7de;
}
ul.inbox-nav li a i {
    padding-right:10px;
    font-size:16px;
    color:#6a6a6a;
}
ul.inbox-nav li a span.label {
    margin-top:13px;
}
ul.labels-info li h4 {
    padding-top:5px;
    color:#5c5c5e;
    font-size:15px;
    text-transform:uppercase;
}
ul.labels-info li {
    margin:0;
}
ul.labels-info li a {
    color:#6a6a6a;
    border-radius:0;
    padding-left:0;
    padding-right:0;
}
ul.labels-info li a:hover,ul.labels-info li a:focus {
    color:#6a6a6a;
    background:none;
}
ul.labels-info li img {
    margin-right:10px;
}
.nav.nav-pills.nav-stacked.labels-info p {
    margin-bottom:0;
    padding:0 22px;
    color:#9d9f9e;
    font-size:11px;
}
.inbox-head {
    padding:20px;
    background:#41cac0;
    color:#fff;
    border-radius:0 4px 0 0;
    -webkit-border-radius:0 4px 0 0;
    min-height:80px;
}
.inbox-head h3 {
    margin:0;
    display:inline-block;
    padding-top:6px;
    font-weight:300;
}
.inbox-head .sr-input {
    height:40px;
    border:none;
    box-shadow:none;
    padding:0 10px;
    float:left;
    border-radius:4px 0 0 4px;
    color:#8a8a8a;
}
.inbox-head .sr-btn {
    height:40px;
    border:none;
    background:#00a6b2;
    color:#fff;
    padding:0 20px;
    border-radius:0 4px 4px 0;
    -webkit-border-radius:0 4px 4px 0;
}
.wht-bg {
    background:#fff;
}
.wht-bg h4 {
    font-size:24px;
    color:#a2a2a2;
    font-weight:300;
}
.table-inbox-wrap {
    margin:0 -15px;
}
.mail-checkbox {
    margin-right:10px;
}
.table-inbox {
    margin:0;
}
.table-inbox tr td {
    padding:12px !important;
}
.table-inbox tr td:first-child {
    width:5%;
}
.table-inbox tr td:first-child .icheckbox_minimal {
    margin-left:15px;
}
.table-inbox tr td:hover {
    cursor:pointer;
}
.table-inbox tr td .fa-star.inbox-started,.table-inbox tr td .fa-star:hover {
    color:#f78a09;
}
.table-inbox tr td .fa-star {
    color:#d5d5d5;
}
.table-inbox tr.unread td {
    font-weight:600;
    background:#f7f7f7;
}

.table-inbox tr.unread a {
    color: #32323a;
}
.table-inbox tr a {
    color: #767676;
}

ul.inbox-pagination {
    float:right;
}
ul.inbox-pagination li {
    float:left;
}

.pagination li a {
    color: #32323a;
}
.mail-option {
    display:inline-block;
    margin-bottom:10px;
    width:100%;
}
.mail-option .chk-all {
    float:left;
}
.mail-option .chk-all,.mail-option .btn-group {
    margin-right:5px;
}
.mail-option .chk-all,.mail-option .btn-group a.btn {
    border:1px solid #e7e7e7;
    padding:5px 10px;
    display:inline-block;
    background:#fcfcfc;
    color:#afafaf;
    border-radius:3px !important;
    -webkit-border-radius:3px !important;
}
.inbox-pagination a.np-btn {
    border:1px solid #e7e7e7;
    padding:5px 15px;
    display:inline-block;
    background:#fcfcfc;
    color:#afafaf;
    border-radius:3px !important;
    -webkit-border-radius:3px !important;
}
.mail-option .chk-all input[type=checkbox] {
    margin-top:0;
}
.mail-option .btn-group a.all {
    padding:0;
    border:none;
}
.inbox-pagination a.np-btn {
    margin-left:5px;
}
.inbox-pagination li span {
    display:inline-block;
    margin-top:7px;
    margin-right:5px;
}
.fileinput-button {
    border:1px solid #e6e6e6;
    background:#eeeeee;
}
.inbox-body .modal .modal-body input,.inbox-body .modal .modal-body textarea {
    border:1px solid #e6e6e6;
    box-shadow:none;
}
.btn-send,.btn-send:hover {
    background:#00A8B3;
    color:#fff;
}
.btn-send:hover {
    background:#009da7;
}
.modal-header h4.modal-title {
    font-weight:300;
    font-family:'Open Sans',sans-serif;
}
.modal-body label {
    font-weight:400;
    font-family: 'Open Sans',sans-serif;
}

.compose-mail {
    width: 100%;
    display: inline-block;
    position: relative;
    margin-top: 20px;
}


.compose-mail .compose-options {
    color: #979797;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 7px;
}

.compose-mail input, .compose-mail input:focus {
    border:none;
    padding: 0;
    width: 80%;
    float: left;
}
.compose-mail .form-group {
    border:1px solid #F2F3F6;
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
}

.compose-mail .form-group label {
    line-height: 34px;
    width: 10%;
    float: left;
    padding-left: 5px;
    margin-bottom: 0;
}

.compose-editor input {
    margin-top: 15px;
}

.compose-editor {
    margin-bottom: 15px;
    display: inline-block;
    width: 100%;
}

.compose-btn {
    float: left;
    margin-top: 6px;
}

.mail-header h4 {
    font-weight: 300;
}

.mail-sender, .attachment-mail {
    width: 100%;
    display: inline-block;
    margin: 20px 0;
    border-top:1px solid #EFF2F7 ;
    border-bottom:1px solid #EFF2F7 ;
    padding: 10px 0;

}

.mail-sender img {
    width: 30px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
}

.mail-sender .date {
    line-height: 30px;
    margin-bottom: 0;
    text-align: right;
}

.view-mail {
    word-wrap: break-word
}

.view-mail a, .attachment-mail a:hover {
    color: #4ECDC4;
}

.attachment-mail a{
    color: #4ECDC4;
}

.attachment-mail ul li  {
    float: left;
    width: 200px;
    margin-right: 15px;
    margin-top: 15px;
    list-style: none;
}

.attachment-mail ul li a.atch-thumb img {
    width: 200px;
    height: 180px;
    margin-bottom: 10px;
}

.attachment-mail ul li a.name span {
    float: right;
    color: #767676;
}


/*CHAT ROOM & LOBBY CONFIGURATION*/

/*chat room*/
.chat-room {
    border-collapse: collapse;
    border-spacing: 0;
    display: table;
    table-layout: fixed;
    width: 100%;
    position: relative;
}

.chat-room aside {
    display: table-cell;
    float: none;
    height: 100%;
    padding: 0;
    vertical-align: top;
}

.chat-room .mid-side {
    width: 75%;
    background: #fff;
    border-right: 1px solid #e5e8ef;
}

.chat-room .right-side {
    width: 25%;
    background: #fff;
}

.chat-room .right-side .user-head {
    background: #3498db;
    color: #FFFFFF;
    min-height: 70px;
    padding: 10px;
    border-left: 1px solid #3498db;
    margin-left: -1px;
    position: relative;
}


.chat-room .user-head i {
   float: left;
   font-size: 40px;
   margin-right: 10px;
}

.chat-room .user-head h3 {
    margin: 6px 0 0 0;
    font-weight: 100;
    letter-spacing: 1px;
}

.chat-room-head {
    background:#4ECDC4;
    color: #FFFFFF;
    min-height: 70px;
    padding: 15px;
}

.chat-room-head h3 {
    margin: 5px 0 0;
    font-weight: 100;
    letter-spacing: 1px;
    display: inline-block;
}

.chat-room-head .search-btn {
    width: 20px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    box-shadow: none;
    background: #34495e;
    padding:0 5px 0 35px;
    margin-top: 2px;
    border: none;
    color: #fff;
}

.chat-room-head .search-btn:focus {
    width: 180px;
    box-shadow: none;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    /*background: #01a6b2;*/
    font-weight: 300;
    color: #fff;
}

.chat-room-head .search-btn:focus::-moz-placeholder {
    color: #fff;
}

ul.chat-list li a {
    color: #6a6a6a;
    display: block;
    padding: 15px;
    font-weight: 300;
}
ul.chat-list li a:hover, ul.chat-list li.active a {
    color: #00a9b4;
    background: #f2f4f7;
}

ul.chat-list li h4 {
    padding: 17px 15px;
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #D5D7DE;
}

ul.chat-list li h4 i {
    padding-right: 5px;
}

ul.chat-list li a span {
    padding-left: 10px;
}

ul.chat-list li a i.fa-times {
    color: #9fa3b0;
}

ul.chat-list li.active {
    color: #00a9b4;
    background: #f2f4f7;
}

ul.chat-list {
    border-bottom: 1px solid #d5d7de;
}

ul.chat-user  {
    margin-bottom: 200px;
}

ul.chat-user li {
    border-bottom: none;
}

ul.chat-user li a:hover{
    background: none;
    color: #6a6a6a;
}

.chat-room .mid-side footer {
    background: #f6f6f6;
    padding: 15px;
    height: 70px;
    width: 75%;
    position: absolute;
    bottom: 0;
    border-right: 1px solid #E5E8EF;
}

.chat-room .right-side footer {
    background: #fff;
    padding: 15px;
    height: 70px;
    width: 25%;
    position: absolute;
    bottom: 0;
    border-top: 1px solid #E5E8EF;
    border-radius: 0 0 4px 0;
    -webkit-border-radius: 0 0 4px 0;
}

.room-desk {
    display: inline-block;
    margin-bottom: 30px;
    width: 100%;
    padding: 15px;
}

.room-desk h4 {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 16px;
    margin: 5px 0 0 0;
}

.room-box {
    border: 1px solid #e7e7e7;
    background: #f7f8fa;
    padding: 10px;
    display: inline-block;
    width: 100%;
    margin-top: 10px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
}

.room-box:hover {
	background: #f0f0f0
}

.room-box h5 {
    margin: 0 0 5px 0;
    font-weight: 300;
    font-size: 16px;
}
.room-box h5 a {
    color: #4ECDC4;
}

.chat-tools {
    float: right;
    padding: 3px;
    width: 40px;
    height: 35px;
    line-height: 30px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    text-align: center;
    margin-top: 6px;
    margin-left: 10px;
}

.chat-tools i {
    font-size: 16px !important;
    float: none !important;
    margin-right: 0 !important;
    color: #fff;
}

.invite-row {
    background: #ecf0f1;
    padding: 6px 10px;
    display: inline-block;
    width: 100%;
}

.invite-row h4 {
    font-size: 16px;
    font-weight: 300;
}

ul.chat-available-user {
    padding: 10px;
}

ul.chat-available-user li {
    margin-bottom: 15px;
}

ul.chat-available-user li a {
    color: #6a6a6a;
}

ul.chat-available-user li a:hover {
    color: #4ECDC4;
}


.group-rom {
    width: 100%;
    float: left;
    border-bottom: 1px solid #eaebee;
}

.group-rom .first-part, .group-rom .second-part, .group-rom .third-part {
    float: left;
    padding: 15px;
}

.group-rom .first-part {
    width: 25%;
}

.group-rom .first-part.odd {
    background: #f7f8fa;
    color: #6a6a6a;
    font-weight: 600;
}

.group-rom .second-part{
    width: 60%;
}

.group-rom .third-part{
    width: 15%;
    color: #d4d3d3;
}

.last-group {
	margin-bottom: 70px;
}

.chat-txt {
    float: left;
    width: 75%;
    margin-right: 5px;
}

.lobby {
    padding: 0 !important;
}


/* GOOGLE MAPS - SCRIPT HELPER*/
#gmap-list,#gmap-tabs {
    height:450px;
}
.gmaps {
    height:300px;
    width:100%;
}
.ullist {
    border-bottom:#ccc 1px solid;
    margin-left:20px;
}
.ullist li {
    display:inline-block;
    margin-left:5px;
}
.ullist li a {
    display:inline-block;
    padding:10px 20px !important;
    border-top:#ccc 1px solid;
    border-left:#ccc 1px solid;
    border-right:#ccc 1px solid;
    -moz-border-radius:3px 3px 0 0;
    -webkit-border-radius:3px 3px 0 0;
    border-radius:3px 3px 0 0;
    background:#f1f1f1;
}
.ullist li.active a {
    background:#fff;
    border-bottom:#fff 1px solid;
    position:relative;
    top:1px;
    margin-bottom: -1px;
}


.btn-facebook {
  color: #fff;
  background-color: #5193ea;
  border-color: #2775e2;
}
.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook:active,
.btn-facebook.active,
.open .dropdown-toggle.btn-facebook {
  color: #fff;
  background-color: #2775e2;
  border-color: #2775e2;
}

.btn-twitter {
  color: #fff;
  background-color: #44ccfe;
  border-color: #2bb4e8;
}
.btn-twitter:hover,
.btn-twitter:focus,
.btn-twitter:active,
.btn-twitter.active,
.open .dropdown-toggle.btn-twitter {
  color: #fff;
  background-color: #2bb4e8;
  border-color: #2bb4e8;
}


/*badge*/
.badge.bg-primary {
    background: #8075c4;
}

.badge.bg-success {
    background: #a9d86e;
}

.badge.bg-warning {
    background: #FCB322;
}

.badge.bg-important {
    background: #ff6c60;
}

.badge.bg-info {
    background: #41cac0;
}

.badge.bg-inverse {
    background: #2A3542;
}


/*easy pie chart*/
.easy-pie-chart {
    display: inline-block;
    padding: 30px 0;
}

.chart-info, .chart-info .increase, .chart-info .decrease {
    display: inline-block;
}

.chart-info {
    width: 100%;
    margin-bottom:5px;
}

.chart-position {
    margin-top: 70px;
}

.chart-info span {
    margin: 0 3px;
}

.chart-info .increase {
    background: #ff6c60;
    width: 10px;
    height: 10px;
}

.chart-info .decrease {
    background: #f2f2f2;
    width: 10px;
    height: 10px;
}

.panel-footer.revenue-foot {
    background-color: #e6e7ec;
    -webkit-border-radius: 0px 0px 4px 4px;
    border-radius: 0px 0px 4px 4px;
    border: none;
    padding: 0;
    width: 100%;
    display: inline-block;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    /* Safari and Chrome */
    .panel-footer.revenue-foot {
        margin-bottom: -4px;
    };
    }


.panel-footer.revenue-foot ul  {
    margin: 0;
    padding: 0;
    width: 100%;
    display: inline-flex;
}

.panel-footer.revenue-foot ul li {
    float: left;
    width: 33.33%;
}

.panel-footer.revenue-foot ul li.first a:hover, .panel-footer.revenue-foot ul li.first a {
    -webkit-border-radius: 0px 0px 0px 4px;
    border-radius: 0px 0px 0px 4px;
}

.panel-footer.revenue-foot ul li.last a:hover, .panel-footer.revenue-foot ul li.last a {
    -webkit-border-radius: 0px 0px 4px 0px;
    border-radius: 0px 0px 4px 0px;
    border-right: none;

}

.panel-footer.revenue-foot ul li a{
    display: inline-block;
    width: 100%;
    padding: 14px 15px;
    text-align: center;
    border-right: 1px solid #d5d8df;
    color: #797979;
}

.panel-footer.revenue-foot ul li a:hover, .panel-footer.revenue-foot ul li.active a {
    background: #fff;
    position: relative;
}

.panel-footer.revenue-foot ul li a i {
    color: #c6cad5;
    display: block;
    font-size: 16px;
}
.panel-footer.revenue-foot ul li a:hover i, .panel-footer.revenue-foot ul li.active a i {
    color: #ff6c60;
    display: block;
    font-size: 16px;
}


/*fontawesome*/
.fontawesome-icon-list h2 {
    margin-top: 0;
    font-size: 20px;
    font-weight: 300;
}

.fontawesome-icon-list .col-sm-4, .fontawesome-icon-list .col-md-3, .fontawesome-icon-list .col-xs-6 {
    margin-bottom: 10px;
}

.fontawesome-icon-list .page-header {
    border-bottom: 1px solid #C9CDD7;
}

.fontawesome-icon-list a {
    color: #797979;
}

.fontawesome-icon-list a:hover{
    color: #35404D;
}

.fontawesome-icon-list i {
    font-size: 16px;
    padding-right: 10px;
}

#web-application, #text-editor, #directional, #video-player, #brand, #medical, #currency {
    margin-top: 10px;
}

/*flot chart*/
.flot-chart .chart, .flot-chart .pie, .flot-chart .bars {
    height: 300px;
}


/*xchart*/
.demo-xchart {
    height: 400px;
    width: 100%;
}


/*todolist*/
#sortable {
    list-style-type: none;
    margin: 0 0 20px 0;
    padding: 0;
    width: 100%;
}
#sortable li {
    padding-left: 3em;
    font-size: 12px;
}
#sortable li i {
    position: absolute;
    left:6px;
    padding:4px 10px 0 10px;
    cursor: pointer;
}

#sortable li input[type=checkbox]{
    margin-top: 0;
}

.ui-sortable > li {
    padding: 15px 0 15px 35px !important ;
    position: relative;
    background: #f5f6f8;
    margin-bottom: 2px;
    border-bottom   : none !important;
}

.ui-sortable li.list-primary {
    border-left: 3px solid #41CAC0;
}

.ui-sortable li.list-success {
    border-left: 3px solid #78CD51;
}

.ui-sortable li.list-danger {
    border-left: 3px solid #FF6C60;
}

.ui-sortable li.list-warning {
    border-left: 3px solid #F1C500;
}

.ui-sortable li.list-info {
    border-left: 3px solid #58C9F3;
}

.ui-sortable li.list-inverse {
    border-left: 3px solid #BEC3C7;
}

/*footer*/
.site-footer {
    background: #4ECDC4;
    color: #fff;
    padding: 15px 0;
}

.site-footer p {
  margin-bottom: 5px;
}

.credits, .credits a {
  color: #fff;
}

.go-top {
    margin-right: 1%;
    margin-top: -25px;
    float: right;
    background: rgba(255,255,255,.5);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
}

.go-top i {
    color: #2A3542;
}

.site-min-height {
    min-height: 900px;
}





/* Contact Form */

#contact {
  padding: 40px 0;
}

.contact-form {

}

.contact-form label {
   color: #999;
}

.contact-form input, .contact-form textarea {
  padding: 10px 12px;
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  height: auto;
}

.contact-form .form-send {
  text-align: center;
}

.contact-form .form-send button  {
  background: #4ECDC4;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  cursor: pointer;
}

.contact-form .form-send button:hover {
  background: #2cb5ab;
}

.contact-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact-form .loading {
  display: none;
  color: #555;
  background: #fff;
  text-align: center;
  padding: 15px;
  margin: 15px 0;
}

.contact-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

@-webkit-keyframes animate-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.contact-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin: 15px 0;
}

.contact-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin: 15px 0;
}

/* Copyrights */

#copyrights {
  background: #222222;
  padding: 20px 0;
  text-align: center;
}

#copyrights p {
  margin-bottom: 5px;
  color: #fff;
}

#copyrights a {
  color: #fff;
}

/*--------------Tasks Widget--------------*/

.task-content {
    margin-bottom: 30px;
}

.task-panel {
	background: #fff;
	text-align: left;
	box-shadow: 0px 3px 2px #aab2bd;
	margin: 5px;
}

.tasks-widget .task-content:after {
	clear: both;
}

.tasks-widget .task-footer  { 
	margin-top: 5px;
}

.tasks-widget .task-footer:after,
.tasks-widget .task-footer:before {
	content: "";
	display: table;
	line-height: 0;
}

.tasks-widget .task-footer:after {
	clear: both;
}

.tasks-widget  .task-list {
  padding:0;
  margin:0;
}

.tasks-widget .task-list > li {
  position:relative;
  padding:10px 5px;
  border-bottom:1px dashed #eaeaea;
}

.tasks-widget .task-list  li.last-line {
  border-bottom:none;
}

.tasks-widget .task-list  li > .task-bell  {
  margin-left:10px;
}

.tasks-widget .task-list  li > .task-checkbox {
	float:left;
	width:30px;
}

.tasks-widget .task-list  li > .task-title  {
  overflow:hidden;
  margin-right:10px;
}

.tasks-widget .task-list  li > .task-config {
	position:absolute;
	top:10px;
	right:10px;
}

.tasks-widget .task-list  li .task-title .task-title-sp  {
  margin-right:5px;
}

.tasks-widget .task-list  li.task-done .task-title-sp  {
  text-decoration:line-through;
  color: #bbbbbb;
}

.tasks-widget .task-list  li.task-done  {
  background:#f6f6f6;
}

.tasks-widget .task-list  li.task-done:hover {
  background:#f4f4f4;
}

.tasks-widget .task-list  li:hover  {
  background:#f9f9f9;
}

.tasks-widget .task-list  li .task-config {
  display:none;
}

.tasks-widget .task-list  li:hover > .task-config {
  display:block;
  margin-bottom:0 !important;
}


@media only screen and (max-width: 320px) {

	.tasks-widget .task-config-btn {
		float:inherit;
		display:block;
	}
	
	.tasks-widget .task-list-projects li > .label {
		margin-bottom:5px;
	}

}
